import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { themeGet } from '../../utils';

import Inner from '../../components/templates/Inner';
import { Box, Flex, Text, Title } from '../../components/atoms';

import { principles as nav } from '../../nav';

const StyledColorBox = styled(Box)`
  width: ${({ size }) => (size === 'm' ? '122px' : '100px')};
  height: ${({ size }) => (size === 'm' ? '122px' : '100px')};
  border-radius: 10px;
`;

const ColorBox = ({ color, size = 'm' }) => (
  <Box m="0 12px 20px 0">
    <Text m="0 0 5px" fontSize="m">
      {color.replace(/projects\./, '')}
    </Text>
    <Text m="0 0 16px" color="grayscale.600" fontSize="xs">
      {themeGet(`colors.${color}`)}
    </Text>
    <StyledColorBox
      bg={color}
      size={size}
      boxShadow={color === 'white' ? '0px 14px 14px rgba(0, 0, 0, 0.07)' : null}
    />
  </Box>
);

ColorBox.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

const ColorBoxWithTheme = withTheme(ColorBox);

const ColorPage = () => (
  <Inner nav={nav} title="Colors">
    <Title mb="xxl">Colors</Title>
    <Title size="h5" mb="m">
      Primary
    </Title>
    <Flex flexWrap="wrap">
      <ColorBoxWithTheme color="brand" />
      <ColorBoxWithTheme color="cta" />
      <ColorBoxWithTheme color="black" />
      <ColorBoxWithTheme color="white" />
      <ColorBoxWithTheme color="grayscale.300" />
      <ColorBoxWithTheme color="grayscale.400" />
      <ColorBoxWithTheme color="grayscale.600" />
      <ColorBoxWithTheme color="grayscale.800" />
      <ColorBoxWithTheme color="link" />
    </Flex>
    <Box mt="xl" mb="m">
      <Title size="h5" m="0" display="inline">
        Secondary
      </Title>
      <Text fontSize="m" display="inline" ml="10px">
        Projects
      </Text>
    </Box>
    <Flex flexWrap="wrap">
      <ColorBoxWithTheme size="s" color="projects.paper" />
      <ColorBoxWithTheme size="s" color="projects.nourish" />
      <ColorBoxWithTheme size="s" color="projects.unight" />
      <ColorBoxWithTheme size="s" color="projects.yoga" />
      <ColorBoxWithTheme size="s" color="projects.sayme" />
      <ColorBoxWithTheme size="s" color="projects.aviapark" />
    </Flex>
  </Inner>
);

export default ColorPage;
